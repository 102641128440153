var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"breadCurmb mt-3"},[_c('label',[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("Home")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t("Resturant")))]),_c('span',[_vm._v("/")]),_c('label',[_c('router-link',{attrs:{"to":{name: 'ingredient'}}},[_vm._v(_vm._s(_vm.$t("Ingredients")))])],1),_c('span',[_vm._v("/")]),_c('label',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t("Edit"): _vm.$t('Add')))])]),_c('v-toolbar-title',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$route.params.id? _vm.$t('EditIngredient') : _vm.$t('AddNewIngredient')))]),_c('v-tabs',{staticClass:"mt-2",attrs:{"background-color":"primary","dark":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('PrimaryData')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('NutritionFacts')))])],1),_c('v-tabs-items',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('v-tab-item',[_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_ar`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameAr')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_ar","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameAr'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.ar),callback:function ($$v) {_vm.$set(_vm.formData.name, "ar", $$v)},expression:"formData.name.ar"}})],1),(_vm.errors.has(`addEditValidation.name_ar`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_ar`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.name_en`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NameEn')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"name_en","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NameEn'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.name.en),callback:function ($$v) {_vm.$set(_vm.formData.name, "en", $$v)},expression:"formData.name.en"}})],1),(_vm.errors.has(`addEditValidation.name_en`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.name_en`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.description_ar`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('DescriptionAr')))]),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"description_ar","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('DescriptionAr'),"hide-details":"","dense":"","outlined":"","rows":"3"},model:{value:(_vm.formData.description.ar),callback:function ($$v) {_vm.$set(_vm.formData.description, "ar", $$v)},expression:"formData.description.ar"}})],1),(_vm.errors.has(`addEditValidation.description_ar`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.description_ar`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":6}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.description_en`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('DescriptionEn')))]),_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"text","name":"description_en","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('DescriptionEn'),"hide-details":"","dense":"","outlined":"","rows":"3"},model:{value:(_vm.formData.description.en),callback:function ($$v) {_vm.$set(_vm.formData.description, "en", $$v)},expression:"formData.description.en"}})],1),(_vm.errors.has(`addEditValidation.description_en`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.description_en`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.unit`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Unit')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"name":"unit","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Unit'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listUnit,"clearable":""},model:{value:(_vm.formData.unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "unit_id", $$v)},expression:"formData.unit_id"}})],1),(_vm.errors.has(`addEditValidation.unit`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.unit`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.division`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Division')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"name":"division","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Division'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listDivision,"clearable":""},model:{value:(_vm.formData.division_id),callback:function ($$v) {_vm.$set(_vm.formData, "division_id", $$v)},expression:"formData.division_id"}})],1),(_vm.errors.has(`addEditValidation.division`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.division`))+" ")]):_vm._e()]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.cost`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Cost')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(''),expression:"''"}],staticClass:"d-block my-2",attrs:{"type":"number","name":"cost","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Cost'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.cost),callback:function ($$v) {_vm.$set(_vm.formData, "cost", $$v)},expression:"formData.cost"}})],1),(_vm.errors.has(`addEditValidation.cost`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.cost`))+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":2}},[_c('span',[_vm._v(_vm._s(_vm.$t('Primary')))])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":4}},[_c('v-switch',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"type":"number","name":"cost","data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Cost'),"hide-details":"","dense":"","outlined":""},model:{value:(_vm.formData.main),callback:function ($$v) {_vm.$set(_vm.formData, "main", $$v)},expression:"formData.main"}})],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('div',[_c('el-button',{attrs:{"type":"primary float-right mb-5"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Add')))])],1),_c('table',{attrs:{"id":"pacakgeFeaturesTable"}},[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("الحقيقة الغدائية")]),_c('th',[_vm._v("الوحدة")]),_c('th',[_vm._v("القيمة")]),_c('th',[_vm._v("العمليات")])]),_vm._l((_vm.formData.nutriotion_fact),function(l,lIndex){return _c('tr',{key:lIndex},[_c('td',[_vm._v(" "+_vm._s(lIndex + 1)+" ")]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.nutriotion_fact_id${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('NutriotionFact')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`nutriotion_fact_id${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('NutriotionFact'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listNutrtionFact,"clearable":""},model:{value:(l.nutriotion_fact_id),callback:function ($$v) {_vm.$set(l, "nutriotion_fact_id", $$v)},expression:"l.nutriotion_fact_id"}})],1),(_vm.errors.has(`addEditValidation.nutriotion_fact_id${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.nutriotion_fact_id${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.unit_ids${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Unit')))]),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`unit_ids${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Unit'),"hide-details":"","dense":"","outlined":"","item-value":"id","item-text":"name","items":_vm.listUnit,"clearable":""},model:{value:(l.unit_ids),callback:function ($$v) {_vm.$set(l, "unit_ids", $$v)},expression:"l.unit_ids"}})],1),(_vm.errors.has(`addEditValidation.unit_ids${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.unit_ids${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('div',{class:{
               'has-error': _vm.errors.has(`addEditValidation.value${lIndex}`),
             }},[_c('label',[_vm._v(_vm._s(_vm.$t('Value')))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"d-block my-2",attrs:{"name":`value${lIndex}`,"data-vv-scope":"addEditValidation","data-vv-as":_vm.$t('Value'),"hide-details":"","dense":"","outlined":""},model:{value:(l.value),callback:function ($$v) {_vm.$set(l, "value", $$v)},expression:"l.value"}})],1),(_vm.errors.has(`addEditValidation.value${lIndex}`))?_c('div',{staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first(`addEditValidation.value${lIndex}`))+" ")]):_vm._e()]),_c('td',[_c('v-icon',{staticClass:"my-0 mr-2 ml-2",attrs:{"dense":"","color":"danger"},on:{"click":function($event){return _vm.removeRow(lIndex);}}},[_vm._v("mdi-delete")])],1)])})],2)])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"float-left"},[_c('v-btn',{staticClass:"mr-2 ml-5",attrs:{"color":"success","dark":"","outlined":"","small":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }